define('ember-cli-loaders/components/loader-cube-transition', ['exports', 'ember-cli-loaders/components/loader-base'], function (exports, _loaderBase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _loaderBase.default.extend({
    classNames: ['cube-transition'],
    divCount: 2
  });
});