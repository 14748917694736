define('ember-cli-autocomplete-input/components/autocomplete-result-item', ['exports', 'ember-cli-autocomplete-input/templates/components/autocomplete-result-item'], function (exports, _autocompleteResultItem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  exports.default = Component.extend({

    layout: _autocompleteResultItem.default,

    // Attributes

    resultName: '',

    resultValue: '',

    // Methods

    getValue: function getValue(object, attrName) {
      if (object.get) {
        return object.get(attrName);
      } else {
        return object[attrName];
      }
    },


    // Properties

    name: computed('result', 'resultName', function () {
      return this.getValue(this.get('result'), this.get('resultName'));
    }),

    value: computed('result', 'resultValue', function () {
      return this.getValue(this.get('result'), this.get('resultValue'));
    }),

    highlightedValue: computed('highlightedResult', 'resultValue', function () {
      var value = '';

      if (this.get('highlightedResult')) {
        value = this.getValue(this.get('highlightedResult'), this.get('resultValue'));
      }

      return value;
    }),

    isHighlighted: computed('value', 'highlightedValue', function () {
      return this.get('value') === this.get('highlightedValue');
    }),

    actions: {
      selectResult: function selectResult(value) {
        this.get('selectResult')(value);
      }
    }

  });
});