define('ember-cli-loaders/components/loader-semi-circle-spin', ['exports', 'ember-cli-loaders/components/loader-base'], function (exports, _loaderBase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _loaderBase.default.extend({
    classNames: ['semi-circle-spin'],
    divCount: 1
  });
});