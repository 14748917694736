define('@ember-decorators/argument/index', ['exports', 'ember-get-config', '@ember-decorators/argument/-private/initializers-meta', '@ember-decorators/argument/-debug'], function (exports, _emberGetConfig, _initializersMeta, _debug) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.argument = argument;

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  var getWithDefault = Ember.getWithDefault;


  var internalArgumentDecorator = function internalArgumentDecorator(target, key, desc, options, validations) {
    if (true) {
      validations.isArgument = true;
      validations.typeRequired = getWithDefault(_emberGetConfig.default, '@ember-decorators/argument.typeRequired', false);
    }

    // always ensure the property is writeable, doesn't make sense otherwise (babel bug?)
    desc.writable = true;
    desc.configurable = true;

    if (desc.initializer === null || desc.initializer === undefined) {
      desc.initializer = undefined;
      return;
    }

    var initializers = (0, _initializersMeta.getOrCreateInitializersFor)(target);
    initializers[key] = desc.initializer;

    desc.initializer = function () {
      var initializers = (0, _initializersMeta.getInitializersFor)(Object.getPrototypeOf(this));
      var initializer = initializers[key];

      var value = this[key];

      var shouldInitialize = options.defaultIfUndefined ? value === undefined : this.hasOwnProperty(key) === false;

      if (shouldInitialize) {
        value = initializer.call(this);
      }

      return value;
    };
  };

  if (true) {
    internalArgumentDecorator = (0, _debug.validationDecorator)(internalArgumentDecorator);
  }

  function argument(maybeOptions, maybeKey, maybeDesc) {
    if (typeof maybeKey === 'string' && (typeof maybeDesc === 'undefined' ? 'undefined' : _typeof(maybeDesc)) === 'object') {
      return internalArgumentDecorator(maybeOptions, maybeKey, maybeDesc, { defaultIfUndefined: false });
    }

    return function (target, key, desc) {
      return internalArgumentDecorator(target, key, desc, maybeOptions);
    };
  }
});