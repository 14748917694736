define('ember-cli-loaders/components/loader-ball-zig-zag-deflect', ['exports', 'ember-cli-loaders/components/loader-base'], function (exports, _loaderBase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _loaderBase.default.extend({
    classNames: ['ball-zig-zag-deflect'],
    divCount: 2
  });
});